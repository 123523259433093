import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from "styled-components";

const scrollOnClick = (event, hash) => {
  event.preventDefault();
  scrollTo(hash)
}
const StyledNavLink = styled(Nav.Link)`
      font-size: .7em;
  `;
const StyledBrand = styled(Navbar.Brand)`
  font-size: .85em;
  `;

const CDNavbar = () => {

  return (
      <Navbar id="navbar" bg="light" expand="lg" sticky="top">
        <StyledBrand onClick={() => scrollTo("#home")}>CarpenDev</StyledBrand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <StyledNavLink href={"#features"}
                           onClick={(event) => scrollOnClick(event,
                               "#features")}>Features</StyledNavLink>
            <StyledNavLink href={"#software"}
                           onClick={(event) => scrollOnClick(event,
                               "#software")}>Software
              Development</StyledNavLink>
            {/* <StyledNavLink href={"#trainings"}
                           onClick={(event) => scrollOnClick(event,
                               "#trainings")}>Training</StyledNavLink> */}
          </Nav>
          <Nav>
            <StyledNavLink href={"#contact-us"}
                           onClick={(event) => scrollOnClick(event,
                               "#contact-us")}>Contact us</StyledNavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
}

export default CDNavbar;